.c-video-card {
  margin: 0 30px;
  &__wrapper {
    display: block;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 16px;

    iframe {
      position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
    }
  }
}
