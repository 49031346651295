.c-funstuff-header {
  padding: 25px 20px;

  position: relative;
  z-index: 0;
  overflow: hidden;

  white-space: nowrap;

  @include lg {
    padding: 0 0 80px;
  };

  h1 {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    max-width: 1110px;


    @include md {
      text-align: left;
    };

    @include lg {
      font-size: 135px;
    };

    span {
      position: relative;
      text-transform: uppercase;
    }

    span:first-of-type {
      color: $color-pink;
    }

    span:last-of-type {
      color: $color-blue;
      top: 40px;
      right: 10px;

      display: inline-block;

      transform: rotateZ(180deg);

      color: $color-blue;

      @include lg {
        right: 50px;
        top: 90px;
      };
    }

    &::after
    {
        display: block;

        content: '+++++++';
        text-align: center;
        letter-spacing: 2px;

        color: $color-teal;

        @include md {
          display: inline-block;
          position: absolute;
          top: 40px;
          content: '+++++++++++++'
        };

        @include lg {
          font-size: 135px;
          top: 90px;
        };
    }
  }

  &::before
  {
      position: absolute;
      z-index: -1;
      top: 20px;
      left: 0;

      width: 100%;
      height: 100%;

      content: '\A';

      opacity: .2;
      background-image: url('/dist/img/blue-plus.png');
      background-position: center;

      @include md {
        transform: rotate(180deg);
      };
  }
}

// .c-funstuff-header
// {
//
//   h1 {
//     font-size: 60px;
//     font-weight: bold;
//     padding: 0 0 20px;
//
//   }
//
//
//     position: relative;
//     z-index: 0;
//
//
//     text-align: center;
//     text-transform: uppercase;
//
//     color: $color-pink;
//
//     @include md {
//       text-align: left;
//     };
//
//     span
//     {
//         position: relative;
//     }
//
//     span:first-of-type
//     {
//         left: 10px;
//     }
//
//     span:last-of-type
//     {
//         top: 40px;
//         right: 10px;
//
//         display: inline-block;
//
//         transform: rotateZ(180deg);
//
//         color: $color-blue;
//     }
//
//     &::before
//     {
//         position: absolute;
//         z-index: -1;
//         top: 20px;
//         left: 0;
//
//         width: 100%;
//         height: 100%;
//
//         content: '\A';
//
//         opacity: .2;
//         background-image: url('/dist/img/blue-plus.png');
//         background-position: center;
//     }
//
//     &::after
//     {
//         display: block;
//
//         content: '+++++++';
//         text-align: center;
//         letter-spacing: 2px;
//
//         color: $color-teal;
//     }
// }
