h1, h2, h3, h4, h5, h6, p, label, input, a {
  color: $color-text;
}

p, label, input, a {
   -webkit-font-smoothing: antialiased;
}

html, button, input, select, textarea, .pure-g [class *= "pure-u"] {
  font-family: $typography-body, sans-serif;
}

.t-section-heading {
  font-family: $typography-headings;
  font-size: 32px;
  line-height: 34px;
  letter-spacing: 1px;
  color: $color-pink;
  text-transform: uppercase;
}

.t-sub-heading {
  font-family: $typography-body;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 3px;

  @include md {
    font-size: 18px;
    letter-spacing: 5px;
    line-height: 26px;
  };
}

.t-body,
.t-intro-body {
  font-family: $typography-body;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}

@include md {
  .t-intro-body {
    font-size: 20px;
    line-height: 34px;
  }
}

.t-label {
  font-family: $typography-headings;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: $color-blue;
}

.t-disclaimer {
  @extend .t-label;
  line-height: 24px;
}

.t-inline-link {
  color: $color-blue;
  position: relative;
  text-decoration: none;
  line-height: 32px;
  transition: color .15s  cubic-bezier(0.455, 0.03, 0.515, 0.955);
  &::after {
    content: '';
    height: 6px;
    width: 98%;
    position: absolute;
    display: block;
    left: 1%;
    bottom: -8px;
    transition: background-color .15s  cubic-bezier(0.455, 0.03, 0.515, 0.955), bottom .15s  cubic-bezier(0.455, 0.03, 0.515, 0.955);
    background-color: $color-teal;
  }

  &:hover, &:active {
    color: $color-pink;

    &::after {
      background-color: $color-pink;
      bottom: -6px;
    }
  }
}

.t-external-link {
  font-size: 15px;
  line-height: 18px;
  color: $color-blue;
  font-family: $typography-headings;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  transition: color .15s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    color: $color-pink;
  }
}
