.c-input {
  display: inline-block;
  width: 100%;
  margin: 15px;

  &:first-of-type {
    margin-left: 0;
    margin-top: 0;
  }

  &--text input, &--textarea textarea {
    display: block;
    height: 46px;
    width: 100%;
    background-color: $color-input-bg;
    border: solid 1px $color-input-border;
    transition: border-color .2s ease-in;
    margin: 10px 0;
    font-family: $typography-body;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 26px;
    padding: 10px 16px;

    &:focus, &:active {
      border-color: $color-blue;
      outline: none;
    }

    &:disabled {
      background-color: $color-shadow;
    }
  }

  &--error input, &--error textarea {
    border-color: $color-pink;
    border-width: 2px;
  }

  &--textarea textarea {
    resize: none;
    height: 160px;
    padding: 16px 1;
    color: $color-text;
    -webkit-font-smoothing: antialiased;
  }

  &--checkbox {

    input {
      width: 25px;
      height: 25px;
      opacity: 0;
      position: absolute;
    }

    label {
      display: inline-block;
      width: calc(100% - 50px);
      vertical-align: top;
      position: relative;
      bottom: 2px;
    }

    input:focus + .c-input__fake-element {
      background-color: transparentize($color-pink, .5);
    }

    input:checked + .c-input__fake-element::after {
      content: '\A';
      background: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2210%22%3E%3Cpath%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%23162149%22%20stroke-width%3D%222%22%20d%3D%22M1%204.493l3.519%203.519L10.531%201%22%2F%3E%3C%2Fsvg%3E') no-repeat center center;
      background-size: 70%;
      font-family: $typography-headings;
      pointer-events: none;
      width: 20px;
      height: 20px;
      position: absolute;
    }


    .c-input__fake-element {
      position: relative;
      margin-right: 20px;
      pointer-events: none;

      display: inline-block;
      width: 20px;
      height: 20px;
      box-shadow: 4px 4px 0 0  $color-pink;
      border-radius: 0;
      border: 2px $color-pink solid;
    }
  }
}

.c-button {
  @extend .t-external-link;
  display: block;
  padding: 11px;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  border: 2px $color-pink solid;
  background: $color-white;
  color: $color-text;
  position: relative;

  &::after {
    content: '\A';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -6px;
    bottom: -5px;
    border-right: 4px $color-pink solid;
    border-bottom: 4px $color-pink solid;
    transition: transform .15s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &:hover::after {
    transform: translate(-4px, -4px);
    opacity: 0;
  }

  &--link:hover {
    border-color: $color-yellow;
    color: $color-yellow;
  }
}
