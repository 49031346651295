$color-pink: #FF6679;
$color-yellow: #FFE66D;
$color-teal: #8AE1D7;
$color-blue: #556FCD;

$color-text: #162149;

$color-input-bg: #F8FAFF;
$color-input-border: #D2D6E5;
$color-shadow: rgba(0,0,0,.1);

$color-white: #ffffff;
