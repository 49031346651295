@font-face {
    font-family: 'Space Mono';
    src: url('SpaceMono-BoldItalic.woff2') format('woff2'),
        url('SpaceMono-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Space Mono';
    src: url('SpaceMono-Italic.woff2') format('woff2'),
        url('SpaceMono-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Space Mono';
    src: url('SpaceMono-Regular.woff2') format('woff2'),
        url('SpaceMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Space Mono';
    src: url('SpaceMono-Bold.woff2') format('woff2'),
        url('SpaceMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Regular.woff2') format('woff2'),
        url('WorkSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$typography-headings: 'Space Mono', monospaced;
$typography-body: 'Work Sans', san-serif;

h1, h2, h3 {
  font-family: $typography-headings;
}

body {
  font-family: $typography-body;
}
